"use client";

// components
import LoginForm from "@/components/Auth/LoginForm";
import LoginFormFooter from "@/components/Auth/LoginFormFooter";

// helpers
import useRouter from "@/hooks/useRouter";

// assets
import styles from "@/assets/styles/pages/auth/auth-login.module.scss";


const LoginPageComponent = ({ message }: { message?: string }) => {
  const router = useRouter();
  
  const handleSubmit = async (userType: string) => {
    if (userType === "hirer") {
      router.push("/go-search");
    }
  };

  return (
    <div className={styles["locl-auth-login"]}>
      <div className={styles["locl-auth-login__form"]}>
        <LoginForm
          message={message}
          onSubmit={handleSubmit as (type: string) => void}
        />
      </div>
      {
        process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL" && (
          <div className={styles["locl-auth-login__footer"]}>
            <LoginFormFooter />
          </div>
        )
      }
    </div>
  );
};

export default LoginPageComponent;
